import { FC } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";

import { ConfigProvider } from "./providers/configProvider/ConfigProvider";
import ConfigChecker from "./ConfigChecker";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: FC = () => (
  <QueryClientProvider client={queryClient}>
    <Router>
      <ConfigProvider>
        <ConfigChecker />
      </ConfigProvider>
    </Router>
  </QueryClientProvider>
);

export default App;
